.dropzone {
  padding: 20px;
  background-color: #f4ff94;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  flex-wrap: wrap;

  &_images {
    display: flex;
    gap: 20px;
  }

  &_uploaderTitle {
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &_uploadedImage {
    width: 120px;
  }

  &_text {
  }
}
