@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  line-height: 150%;
  font-family: 'Montserrat', sans-serif;
}

body, p {
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  //background-color: #f5eedc;
}
