@import 'src/styles/_normalize';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 60px 0;
  text-align: center;

  @media (max-width: 1200px) {
    margin: 30px 0;
    font-size: 24px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;

  @media (max-width: 1200px) {
    width: 80vw;
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
  }

  &_wrap {
    display: flex;
    width: 100%;
    //justify-content: center;
    //align-items: center;
    margin-bottom: 100px;
    background: white;
    max-width: max-content;
    //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
  }

  &_button {
    width: 200px;
    align-self: center;
  }

  &_input {
    width: 100%;

    &_text {
      margin-bottom: 15px;
      font-size: 14px;
      max-width:740px;
    }

    &_dropzone {
      margin-bottom: 30px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5%;
  height: 115px;
  background: #F9C50D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__logo {
    height: 100%;

    a {
      height: 100%;
    }

    img {
      height: 100%;

      @media (max-width: 1200px) {
        height: 80%;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 1200px) {
      gap: 8px;
    }

    a {
      display: flex;
      gap: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      text-decoration: none;
      height: 100%;
      color: #000c;
      justify-content: flex-end;

      @media (max-width: 1200px) {
        font-size: 12px;
      }
    }

    img {
      height: 17px;
      width: 17px;
    }
  }
}

.qna {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  width: 1000px;
  margin: 0 15px 100px 15px;

  @media (max-width: 1200px) {
    width: 80vw;
  }

  &_title {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      font-size: 24px;
    }
  }
}

.bonus_instruction {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 30px;

  &_title {
    font-size: 18px;
  }

  &_item {
    font-size: 14px;
    word-wrap: break-word;
    max-width: 760px;
  }
}

.submit {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer {
  height: 19px;
  width: 100%;
  background: #FFC40C;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 0;
  left: 0;
}

.main {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  max-width: max-content;
  border-radius: 11px;
  margin: 30px;

  @media (max-width: 450px) {
    padding: 20px;
  }
}

.bonus {
  display: flex;
  flex-direction: column;
  //align-items: center;
}

.message {
  font-size: 32px;
  margin: 20px 0 50px 0;
  text-align: center;
  max-width: 50vw;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
}

.input_field {
  width: 77vh;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.accordion {
  margin-bottom: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-bottom: 32px;
}

.phoneError {
  color: red;
  margin: 10px 0;
  font-size: 12px;
}

.warning {
  &_text {
    font-family: 'Montserrat', 'sans-serif';
    margin-top: 20px;
    font-weight: 800;
  }
}
